import {useBreakpointValue, VStack} from "@chakra-ui/react";
import { FaMicrophone, FaGlobe, FaShieldAlt, FaCheckCircle } from 'react-icons/fa';
import TwoColumnRow from "./TwoColumnRow";
import {useAuth} from "../../redux/AuthProvider"; // Import the reusable component

function TwoColumnLayoutVideo({theFileInput, onOpenSignUpScreen1, urlInput, setUrlInput, handleSubmit,
                             currentPlaceholder, fade}) {
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm' || breakpoint === 'md';
    const auth = useAuth();
    //test commit

    const rowsData = [
        {
            title: "Chat with YouTube videos.",
            description:
                "Tired of long videos and struggling to find key content? Upload any YouTube video and chat with it.",
            features: [
                { icon: FaMicrophone, text: "Timestamp citations so you know exactly where the information is" },
                { icon: FaGlobe, text: "Generate detailed notes and summarize in seconds" },
                { icon: FaGlobe, text: "Search for key information easily" },
            ],
            buttonText: "Get started",
            mediaSrc: "/3_screenshot.gif",
            mediaType: "image",
        },
        {
            title: "Chat with any PDF.",
            description:
                "Extract key information, summarize, generate notes or practice questions, and much more. Ask any question and get what you're looking for.",
            features: [
                { icon: FaMicrophone, text: "Supports scanned documents with OCR technology" },
                { icon: FaGlobe, text: "Supports up to 400 pages" },
                { icon: FaShieldAlt, text: "Documents are fully encrypted, and only you can access them." },
            ],
            buttonText: "Get started",
            mediaSrc: "/1_screenshot.jpg",
            mediaType: "image",
        },
        {
            title: "Infinitely better responses.",
            description:
                "The little things matter! We produce the highest-quality responses that are accurate, reliable, and highly detailed. " +
                "Don't opt for other websites that just glaze over your videos and documents.",
            features: [
                { icon: FaMicrophone, text: "Detailed, in depth responses" },
                { icon: FaGlobe, text: "Timestamp and page number citations" },
                { icon: FaCheckCircle, text: "Closely follows your instructions" },
            ],
            buttonText: "Get started",
            mediaSrc: process.env.PUBLIC_URL + '/2_screenshot.mp4',
            mediaType: "video",
            reverse: true,
        },
        {
            title: "Ask about an image—right in your document.",
            description:
                "Encounter a graph, diagram, or tough math equation in your document? " +
                "Just select it and ask a question about it.",
            features: [
                { icon: FaMicrophone, text: "Uses context from your document for a better response" },
                { icon: FaGlobe, text: "No need to leave the page. Works on graphs, diagrams, tables, equations, and more" },
            ],
            buttonText: "Get started",
            mediaSrc: process.env.PUBLIC_URL + '/4_screenshot.mp4',
            mediaType: "video",
            reverse: true,
        },
    ];

    return (
        <VStack spacing={isMobile ? 16 : 20} width={['96%','89%', '81%', '73%', '64%']} p={8} bg={'white'}>
            {rowsData.map((row, index) => (
                <TwoColumnRow
                    key={index}
                    title={row.title}
                    description={row.description}
                    features={row.features}
                    buttonText={!auth.isLoggedIn ? row.buttonText : "Try now!"}
                    mediaSrc={row.mediaSrc}
                    mediaType={row.mediaType}
                    reverse={row.reverse}
                    theFileInput={theFileInput}
                    onOpenSignUpScreen1={onOpenSignUpScreen1}
                    urlInput={urlInput}
                    setUrlInput={setUrlInput}
                    handleSubmit={handleSubmit}
                    currentPlaceholder={currentPlaceholder}
                    fade={fade}
                />
            ))}
        </VStack>
    );
}

export default TwoColumnLayoutVideo;

