import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Grid,
    GridItem,
    VStack,
    Text,
    HStack,
    useDisclosure, useToast, Button, useBreakpointValue,
} from '@chakra-ui/react';
import {MySpinner} from "../../components/Reuseable/MySpinner";
import MySkeletonText from "../../components/Reuseable/MySkeletonText";
import VerticalMenu from "../../components/Reuseable/VerticalMenu";
import DeleteScreen1 from "../../components/Modals/DeleteModal/DeleteScreen1";
import {deleteDocument, renameDocument, renameVideo} from "../../utils/home-page-helpers";
import {fetchDocuments} from "../../utils/redux-helpers";
import {changeNameOfDocument, changeNameOfVideo, setCurrentPageGrid, setDocuments} from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../redux/AuthProvider";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {createThumbnail} from "../../utils/pdf-page-helpers";
import {useTranslation} from "react-i18next";
import DeleteScreen1Video from "../../components/Modals/DeleteModal/DeleteScreen1Video";

const DocumentGridVideos = ({ navigate }) => {
    const { isOpen: isOpenDelete1, onOpen: onOpenDelete1, onClose: onCloseDelete1 } = useDisclosure();
    const [selectedFileID, setSelectedFileID] = useState(null);
    const [editableId, setEditableId] = useState(null);
    const [newNames, setNewNames] = useState({});
    const inputRef = useRef(null);
    const toast = useToast();
    const dispatch = useDispatch();
    const auth = useAuth();
    const documents = useSelector((state) => state.documents.savedVideos);  // only change here
    const [thumbnails, setThumbnails] = useState({});
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const {t} = useTranslation();

    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setEditableId(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handlePostRequest = async (newName, userID, fileID) => {
        await renameVideo(newName, userID, fileID).then((response) => {
            if (response === null) {
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 400) {
                toast({
                    title: t("400 error. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                dispatch(changeNameOfVideo({
                    newName: newName,
                    documentID: fileID,
                }));
                toast({
                    title: t("Successfully renamed."),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            }
        });
    };

    const documentsPerPage = 20; // Set the number of documents per page
    const currentPageGrid = useSelector((state) => state.settings.currentPageGrid);
    const totalPages = documents !== null ? Math.ceil(documents.length / documentsPerPage) : 0;

    // Function to calculate the slice of documents to display
    const indexOfLastDoc = currentPageGrid * documentsPerPage;
    const indexOfFirstDoc = indexOfLastDoc - documentsPerPage;
    const currentDocuments = documents.slice(indexOfFirstDoc, indexOfLastDoc);

    // Function to change page
    const paginate = (pageNumber) => dispatch(setCurrentPageGrid(pageNumber));

    // Function to go to the next page
    const nextPage = () => dispatch(setCurrentPageGrid(Math.min(currentPageGrid + 1, totalPages)));

    // Function to go to the previous page
    const prevPage = () => dispatch(setCurrentPageGrid(Math.max(currentPageGrid - 1, 1)));

    useEffect(() => {
        currentDocuments.forEach(async (document) => {
            if (!thumbnails[document.id]) {
                setThumbnails((prevThumbnails) => ({
                    ...prevThumbnails,
                    [document.id]: { loading: true },
                }));

                try {
                    const thumbnailDataUrl = document.thumbnail_url;
                    setThumbnails((prevThumbnails) => ({
                        ...prevThumbnails,
                        [document.id]: { loading: false, dataUrl: thumbnailDataUrl },
                    }));
                } catch (error) {
                    console.error('Error creating thumbnail Missing PDF Exception:', error);
                    setThumbnails((prevThumbnails) => ({
                        ...prevThumbnails,
                        [document.id]: { loading: false, error: true },
                    }));
                }
            }
        });
    }, [currentDocuments]);

    return (
        <VStack>
            {!isMobile ?
                <HStack justify="space-between" align="center" mt={'-4rem'} mb={'2rem'} width={'100%'} >
                    <Text fontSize={"15px"}>
                        {t("Showing")} {indexOfFirstDoc + 1}-{indexOfLastDoc > documents.length ? documents.length : indexOfLastDoc} of {documents.length}
                    </Text>

                    <HStack spacing={3}>
                        <Button onClick={prevPage} isDisabled={currentPageGrid === 1} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronLeft size={'1.23rem'}/>
                        </Button>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Button
                                onClick={() => paginate(i + 1)}
                                key={i}
                                colorScheme={currentPageGrid === i + 1 ? 'orange' : 'gray'}
                                padding={0}
                                size={'sm'}
                                borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}
                            >
                                {i + 1}
                            </Button>
                        ))}
                        <Button onClick={nextPage} isDisabled={currentPageGrid === totalPages} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronRight size={'1.23rem'}/>
                        </Button>
                    </HStack>
                </HStack> :
                <VStack justify="space-between" align="center" mt={'-4rem'} mb={'2rem'} width={'100%'} spacing={5}>
                    <Text fontSize={"15px"}>
                        {t("Showing")} {indexOfFirstDoc + 1}-{indexOfLastDoc > documents.length ? documents.length : indexOfLastDoc} of {documents.length}
                    </Text>

                    <HStack spacing={3}>
                        <Button onClick={prevPage} isDisabled={currentPageGrid === 1} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronLeft size={'1.23rem'}/>
                        </Button>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Button
                                onClick={() => paginate(i + 1)}
                                key={i}
                                colorScheme={currentPageGrid === i + 1 ? 'orange' : 'gray'}
                                padding={0}
                                size={'sm'}
                                borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}
                            >
                                {i + 1}
                            </Button>
                        ))}
                        <Button onClick={nextPage} isDisabled={currentPageGrid === totalPages} padding={0} size={'sm'} borderColor={'lightgray'} borderWidth={0.25}
                                shadow={`1.5px 1.8px 0px lightgray`}>
                            <FiChevronRight size={'1.23rem'}/>
                        </Button>
                    </HStack>
                </VStack>}
            <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(4, 1fr)" }}
                  gap={'1.5rem'}>
                {currentDocuments.map((document) => (
                    <GridItem key={document.id}>
                        <Box>
                            {thumbnails[document.id]?.loading || !thumbnails[document.id] ? (
                                <VStack spacing={'1rem'} borderRadius={18}>
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'12rem'} width={'16rem'}
                                         borderWidth={'1px'} borderColor={'lightgray'}>
                                        <MySpinner/>
                                    </Box>
                                    <MySkeletonText height={'1rem'} width={'16rem'} count={3} />
                                </VStack>
                            ) : (
                                <VStack spacing={'0rem'} cursor="pointer" borderRadius={18}
                                        borderWidth={'1px'} borderColor={'lightgray'}
                                        _hover={{ borderColor: 'blue.400', borderWidth:'1px'}}>
                                    <a href={`/video/${document.id}`}>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'12rem'} width={'16rem'} borderRadius={18}
                                             onClick={() => navigate(`/video/${document.id}`)}>
                                            <img src={thumbnails[document.id]?.dataUrl}
                                                 style={{
                                                     height: '100%',
                                                     width: '100%',
                                                     objectFit: 'cover',
                                                     borderTopRightRadius: 18,
                                                     borderTopLeftRadius: 18,
                                                 }}
                                                 alt={document.name} />
                                        </Box>
                                    </a>
                                    <HStack width={'16rem'} spacing={0} justifyContent={auth.isLoggedIn ? 'flex-start' : 'center'}
                                            borderTopWidth={'1px'} borderColor={'lightgray'} py={'0.5rem'}
                                            pr={'0.5rem'} pl={auth.isLoggedIn ? '0rem' : '0.5rem'}>
                                        {auth.isLoggedIn ?
                                            <VerticalMenu
                                                menuOptions={[
                                                    {
                                                        label: t("Rename Video"),
                                                        onClick: () => {
                                                            setEditableId(document.id);
                                                            setNewNames({ ...newNames, [document.id]: document.name });
                                                        },
                                                    },
                                                    {
                                                        label: t("Delete Video"),
                                                        onClick: () => {
                                                            setSelectedFileID(document.id);
                                                            onOpenDelete1();
                                                        },
                                                    },
                                                ]}
                                            /> : null}
                                        {editableId === document.id ? (
                                            <input
                                                ref={inputRef}
                                                type="text"
                                                value={newNames[document.id] || ''}
                                                onChange={(e) => setNewNames({ ...newNames, [document.id]: e.target.value })}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handlePostRequest(newNames[document.id], localStorage.getItem("userID"), document.id).then(r => {

                                                        });
                                                        setEditableId(null);
                                                    }
                                                }}
                                                style={{ fontSize: 'small', width: '100%', cursor: 'text', border: '1px solid gray',
                                                    padding: '0.25rem', borderRadius: '0.25rem'}}
                                            />
                                        ) : (
                                            <Text fontSize={'sm'} fontWeight={'normal'} noOfLines={2}
                                                  onClick={() => navigate(`/video/${document.id}`)}>
                                                <a href={`/video/${document.id}`}>
                                                    {document.name}
                                                </a>
                                            </Text>
                                        )}
                                    </HStack>
                                </VStack>
                            )}
                        </Box>
                    </GridItem>
                ))}
                {selectedFileID !== null ?
                    <DeleteScreen1Video onCloseDelete1={onCloseDelete1} isOpenDelete1={isOpenDelete1} userID={localStorage.getItem("userID")}
                                   fileID={selectedFileID}/> : null}
            </Grid>
        </VStack>
    );
};

export default DocumentGridVideos;
