
export const addDocumentInStorage = (document) => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    documents.savedDocuments.push(document);
    localStorage.setItem("documents", JSON.stringify(documents));
};

export const getDocumentsInStorage = () => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    return documents.savedDocuments;
}

export const addDocumentInStorageVideo = (video) => {
    const videos = JSON.parse(localStorage.getItem("videos"));
    videos.savedVideos.push(video);
    localStorage.setItem("videos", JSON.stringify(videos));
};

export const getDocumentsInStorageVideo = () => {
    const videos = JSON.parse(localStorage.getItem("videos"));
    return videos.savedVideos;
}

export const getDocumentsInStorageGeneration = () => {
    const documents = JSON.parse(localStorage.getItem("generations"));
    return documents.savedGenerations;
}












