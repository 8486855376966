import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FeatureContext = createContext();

export const useFeature = () => useContext(FeatureContext);

export const FeatureProvider = ({ children }) => {
    const [feature, setFeature] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Determine feature from base URL
        if (location.pathname.startsWith('/vid')) {
            setFeature('vid');
        } else if (location.pathname.startsWith('/search')) {
            setFeature('search');
        } else {
            setFeature(null); // Default or general feature
        }
    }, [location]);

    return (
        <FeatureContext.Provider value={{ feature }}>
            {children}
        </FeatureContext.Provider>
    );
};




//     const { feature } = useFeature();
//     const officialRender = () => {
//         switch (feature) {
//             case 'video-feature':
//                 return "Explore our powerful video editing and generation features!";
//             case 'multidoc-feature':
//                 return "Optimize your experience with our advanced search tools!";
//             default:
//                 return "Welcome to HoneyBear AI, your all-in-one solution.";
//         }
//     };
