import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    Box,
    Button,
    useToast,
    VStack,
    Text,
    useDisclosure,
    Stack,
    Heading,
    Flex,
    Container,
    useColorModeValue,
    Icon,
    useBreakpointValue,
    InputGroup,
    Tooltip,
    Image,
    InputLeftAddon, InputRightAddon, Input, HStack, Badge, Link, Tabs, TabList, Tab, TabPanels, TabPanel,
} from '@chakra-ui/react';
import axios from 'axios';
import DocumentGrid from './DocumentGrid';
import {createThumbnail} from '../../utils/pdf-page-helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../redux/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDocument, addVideo, removeDocument, setCurrentChatInput,
    setDocuments, setIsPricingModalOpen,
    setIsUploadLoading, setScanError, setScanEstimatedSeconds, setScanFileID, setVideos, setDocumentsGeneration
} from '../../redux/reducers';
import { addDocumentInStorage, getDocumentsInStorage } from '../../utils/local-storage-helpers';
import {influencerMap, SERVER_IP, SERVER_IP_VIDEO} from "../../utils/constants";
import {useDropzone} from "react-dropzone";
import { motion } from 'framer-motion';
import SignUpScreen1 from "../../components/Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../../components/Modals/SignIn/SignInScreen1";
import LargeWithNewsletter from "../../components/Reuseable/LargeWithNewsletter";
import LoadingBar from "./LoadingBar";
import FullScreenTemplateHomePage from "../../templates/FullScreenTemplateHomePage";
import {
    FcAddImage,
    FcDocument, FcLock, FcMultipleInputs, FcQuestions, FcReading, FcSportsMode, FcViewDetails,
} from 'react-icons/fc'
import ReactPlayer from "react-player/lazy";
import LazyLoad from 'react-lazyload';
import lightTheme from "../../utils/styles";
import {fetchDocuments, fetchVideos} from "../../utils/redux-helpers";
import ScanModal1 from "../../components/Modals/Scans/ScanModal1";
import ScanModalIntro1 from "../../components/Modals/Scans/ScanModalIntro1";
import WithSpeechBubbles from "./WithSpeechBubbles";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import '../../fonts.css';
import {useTranslation} from "react-i18next";
import LogoCarousel from "../../components/Reuseable/LogoCarousel";
import URLInput from "./URLInput";
import DocumentGridVideos from "./DocumentGridVideos";
import DocumentGridGeneration from "./DocumentGridGeneration";
import DocumentGridGenerationEmpty from "./DocumentGridGenerationEmpty";
import DocumentGridVideosEmpty from "./DocumentGridVideosEmpty";
import TwoColumnLayout from "./TwoColumnLayout";
import BigTestimonial from "./BigTestimonial";
import DocumentGridEmpty from "./DocumentGridEmpty";
import {initializeMultidoc} from "../../utils/multi-doc-helpers";
import {fetchMultidocs} from "../../utils/multi-doc-helpers";

// import localForage from 'localforage';
//
// // Configure localForage to use your application's name and store name
// localForage.config({
//     name: 'MyApp',
//     storeName: 'thumbnails', // name of the datastore
// });
const MotionBox = motion(Box);

const NewUnifiedHomePage = () => {
    const {t} = useTranslation();
    const featuresRef = useRef(null);
    const testimonialsRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const toast = useToast();
    const fileInput = useRef();
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const { isOpen: isOpenScanModal1, onOpen: onOpenScanModal1, onClose: onCloseScanModal1 } = useDisclosure();
    const { isOpen: isOpenScanModalIntro1, onOpen: onOpenScanModalIntro1, onClose: onCloseScanModalIntro1 } = useDisclosure();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const isUploadLoading = useSelector(state => state.settings.isUploadLoading);
    const [uploadProgress, setUploadProgress] = useState(0);
    const auth = useAuth();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    const navigateToTestimonials = () => {
        if (testimonialsRef.current) {
            const offsetTop = testimonialsRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    }

    const navigateToFeatures = () => {
        if (featuresRef.current) {
            const offsetTop = featuresRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        if (hash) {
            if (hash === 'features' && featuresRef.current) {
                const offsetTop = featuresRef.current.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: offsetTop - 160, behavior: 'smooth' });
            } else if (hash === 'testimonials' && testimonialsRef.current) {
                const offsetTop = testimonialsRef.current.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: offsetTop - 140, behavior: 'smooth' });
            }
        }
    }, []);

    // WTFFFFFFFFFFFFFFFFFFFF
    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab === 'videos') {
            setTabIndex(2); // Set Videos tab active
        } else if (tab === 'multidoc') {
            setTabIndex(1); // Set Multidoc tab active
        } else {
            setTabIndex(0); // Default to PDFs tab
        }
    }, [searchParams]);

    const handleTabChange = (index) => {
        if (index === 0) {
            setSearchParams({ tab: 'pdfs' });
        } else if (index === 1) {
            setSearchParams({ tab: 'multidoc' });
        } else if (index === 2) {
            setSearchParams({ tab: 'videos' });
        }
        // setSearchParams({ tab: index === 0 ? 'pdfs' : 'videos' }); // Update URL with query parameter
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
            toast({
                title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            dispatch(setIsPricingModalOpen(true));
            return;
        }

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            handleFileUpload({target: {files: [file]}}).then(r =>{
                navigate('/?tab=pdfs');
            });
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: false,
    });

    // Define the upload function based on the user authentication state
    const handleFileUpload = async (event) => {
        console.log("USERID BELOW: ")
        //console.log(localStorage.getItem("userID"));

        const document = event.target.files[0];
        if (localStorage.getItem("userID") !== null) {
            if (document) {
                if (document.type === 'application/pdf') {
                    dispatch(setIsUploadLoading(true));
                    const formData = new FormData();
                    formData.append('file', document);
                    formData.append('user_id', localStorage.getItem("userID"));
                    formData.append('file_name', document.name);
                    console.log("FORM DATA BELOW: ")
                    //console.log(formData.forEach((value, key) => console.log(key + ' ' + value)));

                    try {
                        const response = await axios.post(SERVER_IP + '/pdf', formData, {
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setUploadProgress(percentCompleted);
                            }
                        });
                        console.log('Uploaded PDF Document Response:', response);


                        if (response.data.status === 207) {
                            dispatch(setScanFileID(response.data.message.file_id));
                            dispatch(setScanEstimatedSeconds(response.data.message.time_estimate_seconds));
                            dispatch(setIsUploadLoading(false));
                            setUploadProgress(0);
                            onOpenScanModal1();
                            dispatch(setScanError(false));
                            dispatch(addDocument({
                                id: response.data.message.file_id,
                                name: response.data.message.filename,
                                url: response.data.message.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            return;
                        }



                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        if (response.data.status === 400) {
                            toast({
                                title: t("PDF is too large."),
                                description: t("We support up to 512 MB or 147,000 words"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        } else if (response.data.status === 401) {
                            toast({
                                title: t("Oops, this PDF failed."),
                                description: t("Please try again or upload a different PDF."),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        } else if (response.data.status === 402 && response.data.message === "free tier exceeded. Please subscribe to continue using the service") {
                            console.log("pricing_modal_shown")
                            dispatch(setIsPricingModalOpen(true));
                            localStorage.setItem("isUploadExceeded", "true");
                            toast({
                                title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                                status: "info",
                                duration: 9000,
                                isClosable: true,
                                position: "top",
                            });
                        } else if (response.data.status === 403) {
                            toast({
                                title: t("Free Plan supports <= 20 pages for scanned PDFs"),
                                description: t("Visit honeybear.ai/pricing to view Pro Plan pricing"),
                                status: "error",
                                duration: null,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 404) {
                            toast({
                                title: t("Scanned PDF Too Large"),
                                description: t("Pro Plan supports up to 200 pages for scanned PDFs"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else {
                            dispatch(addDocument({
                                id: response.data.file_id,
                                name: response.data.filename,
                                url: response.data.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            toast({
                                title: t("Document uploaded."),
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            });
                        }
                    } catch (error) {
                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        toast({
                            title: t("Oops, this PDF failed."),
                            description: t("Please try again or upload a different PDF."),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        console.error('Error uploading the document:', error);
                    }
                } else {
                    toast({
                        title: t("Upload failed."),
                        description: t("We only support PDF documents."),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        } else {
            if (document) {
                if (getDocumentsInStorage().length === 1) {
                    toast({
                        title: t("You've reached your document limit in guest mode!"),
                        description: t("Please sign up to continue uploading documents."),
                        status: "info",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                    });
                    onOpenSignUpScreen1()
                    return;
                }
                if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
                    toast({
                        title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                        status: "info",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                    });
                    dispatch(setIsPricingModalOpen(true));
                    return;
                }
                if (document.type === 'application/pdf') {
                    dispatch(setIsUploadLoading(true));
                    const formData = new FormData();
                    formData.append('file', document);
                    formData.append('user_id', "11111111-1111-1111-1111-111111111111");
                    formData.append('file_name', document.name);
                    try {
                        const response = await axios.post(SERVER_IP + '/pdf', formData, {
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setUploadProgress(percentCompleted);
                            }
                        });



                        if (response.data.status === 207) {
                            dispatch(setScanFileID(response.data.message.file_id));
                            dispatch(setScanEstimatedSeconds(response.data.message.time_estimate_seconds));
                            dispatch(setIsUploadLoading(false));
                            setUploadProgress(0);
                            onOpenScanModal1();
                            dispatch(setScanError(false));
                            dispatch(addDocument({
                                id: response.data.message.file_id,
                                name: response.data.message.filename,
                                url: response.data.message.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            return;
                        }



                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        if (response.data.status === 400) {
                            toast({
                                title: t("PDF is too large."),
                                description: t("We support up to 512 MB or 147,000 words"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 401) {
                            toast({
                                title: t("Oops, this PDF failed."),
                                description: t("Please try again or upload a different PDF."),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 402) {
                            toast({
                                title: t("Oops, this PDF failed."),
                                description: t("Please try again or upload a different PDF."),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        } else if (response.data.status === 403) {
                            toast({
                                title: t("Free Plan supports <= 20 pages for scanned PDFs"),
                                description: t("Visit honeybear.ai/pricing to view Pro Plan pricing"),
                                status: "error",
                                duration: null,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 404) {
                            toast({
                                title: t("Scanned PDF Too Large"),
                                description: t("Pro Plan supports up to 200 pages for scanned PDFs"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else {
                            addDocumentInStorage({
                                id: response.data.file_id,
                                name: response.data.filename,
                                url: response.data.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            })
                            auth.setPlaygroundDocuments(getDocumentsInStorage());
                            dispatch(addDocument({
                                id: response.data.file_id,
                                name: response.data.filename,
                                url: response.data.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            toast({
                                title: t("Document uploaded."),
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            })
                        }

                    } catch (error) {
                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        toast({
                            title: t("Oops, this PDF failed."),
                            description: t("Please try again or upload a different PDF."),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        console.error('Error uploading the document:', error);
                    }
                } else {
                    toast({
                        title: t("Upload failed."),
                        description: t("We only support PDF documents."),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        }
    };

    const handleYoutubeLinkUpload = async (url) => {
        console.log("YOUTUBE URL", url);
        if (localStorage.getItem("userID") !== null) {
            if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
                toast({
                    title: t("(Free Plan) Video Upload Limit Exceeded!"),
                    status: "info",
                    duration: 9000,
                    isClosable: true,
                    position: "top",
                });
                dispatch(setIsPricingModalOpen(true));
                return;
            }
            dispatch(setIsUploadLoading(true));
            const formData = new FormData();
            formData.append('yt_link', url);
            formData.append('data_type', "yt_link");
            formData.append('user_id', localStorage.getItem("userID"));

            // console.log("video input", {
            //     yt_link: url,
            //     data_type: "yt_link",
            //     user_id: localStorage.getItem("userID"),
            // })
            try {
                const response = await axios.post(SERVER_IP_VIDEO + '/videos/video', formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                console.log('Uploaded Video Response new:', response);
                dispatch(setIsUploadLoading(false));
                setUploadProgress(0);
                if (response.data.status === 400 && (response.data.message === "Invalid YouTube link" || response.data.message === "Invalid video ID")) {
                    console.log("video_upload_error")
                    toast({
                        title: t("Invalid YouTube link"),
                        description: t("Please enter a valid YouTube link"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 400 && response.data.message === "YouTube request failed") {
                    console.log("video_upload_error")
                    toast({
                        title: t("Upload Failed"),
                        description: t("Please try again or upload a different video"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 400 && response.data.message === "Video unavailable") {
                    console.log("video_upload_error")
                    toast({
                        title: t("Video is unavailable"),
                        description: t("Please make sure it's not a private video"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 400 && (response.data.message === "No transcript available"
                    || response.data.message === "No transcript found" || response.data.message === "Transcript is None for some reason")) {
                    console.log("video_upload_error")
                    toast({
                        title: t("Video doesn't have a transcript"),
                        description: t("Please try a different video"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 400 && response.data.message.startsWith("Error getting transcript:")) {
                    console.log("video_upload_error")
                    toast({
                        title: t("Please try again!"),
                        description: t("If the issue persists, please try a different video as this video may not have a transcript"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 400 && response.data.message.startsWith("Error: ")) {
                    console.log("video_upload_error")
                    toast({
                        title: t("Please try again!"),
                        description: t("If the issue persists, please try a different video"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 400
                    && response.data.message === "Video transcript too big, try working with a smaller video") {
                    console.log("video_upload_error")
                    toast({
                        title: t("Video too large"),
                        description: t("Please try a shorter length video"),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else if (response.data.status === 402 && response.data.message === "free tier exceeded. Please subscribe to continue using the service") {
                    console.log("pricing_modal_shown")
                    dispatch(setIsPricingModalOpen(true));
                    localStorage.setItem("isUploadExceeded", "true");
                    toast({
                        title: t("(Free Plan) Upload Limit Exceeded!"),
                        status: "info",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                    });
                }
                else {
                    console.log("video_upload_success")
                    dispatch(addVideo({
                        id: response.data.file_id,
                        name: response.data.filename,
                        url: response.data.url,
                        thumbnail_url: response.data.thumbnail_presigned_url,
                        highlights: [],
                        messages: [],
                        messagesGeneration: [],
                    }));
                    toast({
                        title: t("Video uploaded."),
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.log("video_upload_error")
                dispatch(setIsUploadLoading(false));
                setUploadProgress(0);
                toast({
                    title: t("Oops, this Video Upload failed."),
                    description: t("Please try again or upload a different video."),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                console.error('Error uploading the video:', error);
            }
        }
        // else {
        //         if (getDocumentsInStorage().length === 1) {
        //             toast({
        //                 title: t("You've reached your document limit in guest mode!"),
        //                 description: t("Please sign up to continue uploading documents."),
        //                 status: "info",
        //                 duration: 9000,
        //                 isClosable: true,
        //                 position: "top",
        //             });
        //             onOpenSignUpScreen1()
        //             return;
        //         }
        //         if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
        //             toast({
        //                 title: t("(Free Plan) PDF Upload Limit Exceeded!"),
        //                 status: "info",
        //                 duration: 9000,
        //                 isClosable: true,
        //                 position: "top",
        //             });
        //             dispatch(setIsPricingModalOpen(true));
        //             return;
        //         }
        //         dispatch(setIsUploadLoading(true));
        //         try {
        //             const response = await axios.post(SERVER_IP + '/videos/video', {
        //                 yt_link: url,
        //                 data_type: "yt_link",
        //                 user_id: "11111111-1111-1111-1111-111111111111",
        //             }, {
        //                 onUploadProgress: (progressEvent) => {
        //                     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //                     setUploadProgress(percentCompleted);
        //                 }
        //             });
        //             dispatch(setIsUploadLoading(false));
        //             setUploadProgress(0);
        //             if (response.data.status === 400) {
        //                 toast({
        //                     title: t("PDF is too large."),
        //                     description: t("We support up to 512 MB or 147,000 words"),
        //                     status: "error",
        //                     duration: 9000,
        //                     isClosable: true,
        //                 })
        //             }
        //             else {
        //                 addDocumentInStorage({
        //                     id: response.data.file_id,
        //                     name: response.data.filename,
        //                     url: response.data.url,
        //                     thumbnail: response.thumbnail_presigned_url,
        //                     highlights: [],
        //                     messages: [],
        //                     messagesGeneration: [],
        //                 })
        //                 auth.setPlaygroundDocuments(getDocumentsInStorage());
        //                 dispatch(addVideo({
        //                     id: response.data.file_id,
        //                     name: response.data.filename,
        //                     url: response.data.url,
        //                     thumbnail_url: response.thumbnail_presigned_url,
        //                     highlights: [],
        //                     messages: [],
        //                     messagesGeneration: [],
        //                 }));
        //                 toast({
        //                     title: t("Video uploaded."),
        //                     status: "success",
        //                     duration: 9000,
        //                     isClosable: true,
        //                 })
        //             }
        //
        //         } catch (error) {
        //             dispatch(setIsUploadLoading(false));
        //             setUploadProgress(0);
        //             toast({
        //                 title: t("Oops, this PDF failed."),
        //                 description: t("Please try again or upload a different PDF."),
        //                 status: "error",
        //                 duration: 9000,
        //                 isClosable: true,
        //             })
        //             console.error('Error uploading the document:', error);
        //         }
        // }
    }

    // Select the correct document list based on authentication
    const documents = useSelector(state => state.documents.savedDocuments);
    const videos = useSelector(state => state.documents.savedVideos);
    const generations = useSelector(state => state.documents.savedGenerations);

    // CHANGE THIS FOR VIDEOS LATER
    useEffect(() => {
        const tempUserID = localStorage.getItem("userID");

        if (tempUserID !== null) {
            // Get the current search parameters
            // const currentSearchParams = new URLSearchParams(window.location.search);
            // const currentTab = currentSearchParams.get('tab');

            // // Decide navigation based on the user's entered path or default
            // if (currentTab === 'videos') {
            //     navigate('/?tab=videos'); // Stay on videos
            //     setTabIndex(2);
            // } else if (currentTab === 'multidoc') {
            //     navigate('/?tab=multidoc'); // Stay on multidocs
            //     setTabIndex(1);
            // } else if (currentTab === 'pdfs' || currentTab === null) {
            //     // Default to PDFs if no `tab` query or explicitly `tab=pdfs`
            //     navigate('/?tab=pdfs');
            //     setTabIndex(0);
            // }

            // Fetch documents and videos
            fetchDocuments(tempUserID).then(r => {
                dispatch(setDocuments(r.documents));
            });
            fetchVideos(tempUserID).then(r => {
                dispatch(setVideos(r.videos));
            });
            fetchMultidocs(tempUserID).then(r => {
                dispatch(setDocumentsGeneration(r.multidocs));
            });
        }
    }, []);


    const placeholders = ["https://www.youtube.com/watch?v=aJwdWeiSc8c",
        "https://www.youtube.com/watch?v=nyOD7m4uOv8"];
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true); // State to manage fade in and out
    const [urlInput, setUrlInput] = useState("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
                setCurrentPlaceholder(placeholders[index]);
                setFade(true);
            }, 750); // Half the interval time to allow for fade in and fade out
        }, 1500);

        return () => clearInterval(intervalId);
    }, [index]);

    const handleSubmit = () => {
        if (!auth.isLoggedIn) {
            toast({
                title: "Please sign up to upload YouTube videos",
                description: "Then you can chat with them just like with normal PDFs!",
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            onOpenSignUpScreen1();
        } else {
            setUrlInput("");
            handleYoutubeLinkUpload(urlInput).then(r => {
                setUrlInput("")
                navigate('/?tab=videos');
            });
        }
    };

    const [tabIndex, setTabIndex] = useState(0);
    const [workspaceLoading, setWorkspaceLoading] = useState(false);
    const areAllFilesEmpty = (...fileArrays) => {
        return fileArrays.every(fileArray => fileArray.length === 0);
    };

    const isAnyFileNonEmpty = (...fileArrays) => {
        return fileArrays.some(fileArray => fileArray.length !== 0);
    };

    const renderTabContent = () => {
        if (tabIndex === 0) {
            return documents.length !== 0 ? (
                <DocumentGrid navigate={navigate} />
            ) : (
                <DocumentGridEmpty />
            );
        }

        if (tabIndex === 1) {
            return generations.length !== 0 ? (
                <DocumentGridGeneration navigate={navigate} />
            ) : (
                <DocumentGridGenerationEmpty />
            );
        }

        if (tabIndex === 2) {
            return videos.length !== 0 ? (
                <DocumentGridVideos navigate={navigate} />
            ) : (
                <DocumentGridVideosEmpty />
            );
        }

        return null; // Default case if tabIndex is not 0, 1, or 2
    };

    return (
        <div>
            <FullScreenTemplateHomePage navigateToTestimonials={navigateToTestimonials} navigateToFeatures={navigateToFeatures}>
                <VStack width={'100%'} height={'100%'} justifyContent={'center'} display={'flex'} spacing={'4rem'}
                        mt={areAllFilesEmpty(documents, videos, generations) ? '3.5rem' : '0rem'}>
                    {isAnyFileNonEmpty(documents, videos, generations) ?
                        isUploadLoading ?
                            <Box width={'60%'} pt={'3.5rem'}>
                                <LoadingBar progress={uploadProgress} isMarginBottom={true}/>
                            </Box> :
                            <VStack width={"100%"} height={'21.75rem'} mb={'1rem'} spacing={6} py={'1.75rem'}
                                    bg={lightTheme.colors.lightGray}>
                                <VStack
                                    width={{
                                        base: '80%',
                                        sm: '70%',
                                        md: '60%',
                                        lg: '55%',
                                        xl: '50%',
                                    }}
                                    bg={'white'}
                                    height={'18rem'}
                                    borderRadius={'15px'}
                                    spacing={4}
                                    borderColor={'lightgray'}
                                    borderWidth={0.25}
                                    shadow={`1.5px 1.8px 0px lightgray`}
                                    position="relative"
                                >
                                    <Tabs
                                        variant="soft-rounded"
                                        colorScheme="orange"
                                        onChange={handleTabChange}
                                        index={tabIndex}
                                        width="100%"
                                        position="absolute"
                                        top="0rem"
                                        left="0rem"
                                        zIndex={1}
                                    >
                                        <TabList>
                                            <Tab borderTopLeftRadius={'15px'}
                                                 borderTopRightRadius={'0px'}
                                                 borderBottomRadius={'0px'}
                                                 borderBottomWidth={'0.25px'}
                                                 borderBottomColor={'orange.100'}
                                                 sx={{
                                                     color: 'gray.600', // Default color
                                                     _selected: {
                                                         color: 'black', // Color when selected
                                                         bg: 'orange.100', // Background color when selected
                                                     },
                                                 }}
                                                 fontSize={15}>Single-PDF</Tab>
                                            <Tab borderRadius={'0px'} sx={{
                                                color: 'gray.600', // Default color
                                                _selected: {
                                                    color: 'black', // Color when selected
                                                    bg: 'orange.100', // Background color when selected
                                                },
                                            }} borderRightWidth={'0.25px'}
                                                 borderRightColor={'orange.100'}
                                                 borderLeftWidth={'0.25px'}
                                                 borderLeftColor={'orange.100'}
                                                 borderBottomWidth={'0.25px'}
                                                 borderBottomColor={'orange.100'}
                                                 fontSize={15}>
                                                <Box display="flex" alignItems="center">
                                                    Multi-PDF
                                                    <Badge
                                                        colorScheme="purple"
                                                        variant="solid"
                                                        ml="3" // Adjust this margin to control spacing between text and badge
                                                        fontSize="0.7em"
                                                        borderRadius="5"
                                                        px="2"
                                                        py="1"
                                                        boxShadow="0px 1px 6px -1px rgba(0, 0, 0, 0.1), 0px 1px 6px -1px rgba(0, 0, 0, 0.1)"
                                                    >
                                                        NEW
                                                    </Badge>
                                                </Box>
                                            </Tab>
                                            <Tab borderRadius={'0px'} sx={{
                                                color: 'gray.600', // Default color
                                                _selected: {
                                                    color: 'black', // Color when selected
                                                    bg: 'orange.100', // Background color when selected
                                                },
                                            }} borderRightWidth={'0.25px'}
                                                 borderRightColor={'orange.100'}
                                                 // borderLeftWidth={'0.25px'}
                                                 // borderLeftColor={'orange.100'}
                                                 borderBottomWidth={'0.25px'}
                                                 borderBottomColor={'orange.100'}
                                                 fontSize={15}>
                                                <Box display="flex" alignItems="center">
                                                    Videos
                                                    {/*<Badge*/}
                                                    {/*    colorScheme="purple"*/}
                                                    {/*    variant="solid"*/}
                                                    {/*    ml="3" // Adjust this margin to control spacing between text and badge*/}
                                                    {/*    fontSize="0.7em"*/}
                                                    {/*    borderRadius="5"*/}
                                                    {/*    px="2"*/}
                                                    {/*    py="1"*/}
                                                    {/*    boxShadow="0px 1px 6px -1px rgba(0, 0, 0, 0.1), 0px 1px 6px -1px rgba(0, 0, 0, 0.1)"*/}
                                                    {/*>*/}
                                                    {/*    NEW*/}
                                                    {/*</Badge>*/}
                                                </Box>
                                            </Tab>
                                        </TabList>

                                        <TabPanels px={2.5}>
                                            <TabPanel>
                                                {/* PDF Upload UI */}
                                                <MotionBox
                                                    {...getRootProps()}
                                                    border="2px"
                                                    borderColor="orange.400"
                                                    borderRadius="md"
                                                    borderStyle="dashed"
                                                    textAlign="center"
                                                    p="3"
                                                    mt={4}
                                                    pt={'1.05rem'}
                                                    cursor="pointer"
                                                    _hover={{ bg: 'gray.100' }}
                                                    animate={isDragActive ? { scale: 1.05 } : { scale: 1 }}
                                                    transition={{ duration: 0.2 }}
                                                    width="100%"
                                                    height="9rem"
                                                    onClick={() => {
                                                        if (getDocumentsInStorage().length === 1 && !auth.isLoggedIn) {
                                                            toast({
                                                                title: t("You've reached your document limit in guest mode!"),
                                                                description: t("Please sign up to continue uploading documents."),
                                                                status: "info",
                                                                duration: 9000,
                                                                isClosable: true,
                                                                position: "top",
                                                            });
                                                            onOpenSignUpScreen1();
                                                        }
                                                        else if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
                                                            toast({
                                                                title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                                                                status: "info",
                                                                duration: 9000,
                                                                isClosable: true,
                                                                position: "top",
                                                            });
                                                            dispatch(setIsPricingModalOpen(true));
                                                        }
                                                        else {
                                                            fileInput.current.click();
                                                        }
                                                    }}
                                                >
                                                    <input {...getInputProps({ accept: 'application/pdf' })} ref={fileInput}/>
                                                    <Box as="span" color="gray.400" fontSize="3xl">
                                                        ⇧
                                                    </Box>
                                                    <Text fontWeight="bold" color="gray.400" fontSize={15}>
                                                        {t("Drag & Drop")}
                                                    </Text>
                                                    <Text color="gray.500" fontSize={15}>{t("or click to browse")}</Text>
                                                </MotionBox>
                                            </TabPanel>

                                            {/*Multi-PDF Upload UI*/}
                                            <TabPanel>
                                                <VStack>
                                                    {auth.isLoggedIn ?
                                                        <Text fontSize={17} color={'black'} mt={7} mb={8} fontWeight="500" textAlign={'center'}>
                                                            Chat with Multiple PDFs at the same time!
                                                        </Text> :
                                                        <Text fontSize={17} color={'black'} mt={7} mb={12} fontWeight="500" textAlign={'center'}>
                                                            Please sign up to chat with multiple PDFs at the same time!
                                                        </Text>
                                                    }

                                                    <Button
                                                        borderRadius={12}
                                                        isLoading={workspaceLoading}
                                                        px={6}
                                                        py={6}
                                                        fontSize={18}
                                                        colorScheme={'orange'}
                                                        bgGradient="linear(to-r, orange.400, orange.500)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, orange.400, orange.600)", // Adjusts the gradient on hover
                                                            boxShadow: 'lg' // Adds a slight shadow on hover
                                                        }}
                                                        onClick={() => {
                                                            if (!auth.isLoggedIn) {
                                                                toast({
                                                                    title: "Please sign up to upload multiple PDFs",
                                                                    description: "Then you can chat with all of them at the same time!",
                                                                    status: "info",
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                    position: "top",
                                                                });
                                                                onOpenSignUpScreen1();
                                                            } else {
                                                                setWorkspaceLoading(true);
                                                                const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                                                                initializeMultidoc("Untitled Workspace", userID).then(r => {
                                                                    setWorkspaceLoading(false);
                                                                    navigate(`/multidoc/${r.id}`)
                                                                    window.location.reload();
                                                                    console.log("initialize multidoc here", r)
                                                                });
                                                            }

                                                        }}>
                                                        Create new Workspace
                                                    </Button>
                                                </VStack>
                                            </TabPanel>
                                            {/*Multi-PDF Upload UI*/}


                                            <TabPanel>
                                                {auth.isLoggedIn ?
                                                    <Text fontSize={17} color={'black'} mt={7} mb={12} fontWeight="500" textAlign={'center'}>
                                                        Enter a YouTube link below. Support for .mp4 files coming soon!
                                                    </Text> :
                                                    <Text fontSize={17} color={'black'} mt={7} mb={12} fontWeight="500" textAlign={'center'}>
                                                        Please sign up to use this feature!
                                                    </Text>
                                                }

                                                <URLInput
                                                    urlInput={urlInput}
                                                    setUrlInput={setUrlInput}
                                                    handleSubmit={handleSubmit}
                                                    currentPlaceholder={currentPlaceholder}
                                                    fade={fade}
                                                />
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </VStack>
                            </VStack>
                        : null}

                    {
                        areAllFilesEmpty(documents, videos, generations) ? (
                            <Container maxW={'100%'} mt={'-8.1rem'} bg={'white'} align={'center'}>
                                <Stack
                                    textAlign={'center'}
                                    align={'center'}
                                    spacing={{ base: 8, md: 10 }}
                                    maxW={'5xl'}
                                    py={{ base: 20, md: 28 }}>
                                    {Object.keys(influencerMap).includes(localStorage.getItem('referralCodeInfluencer')) ?
                                        <Text className="caveat-influencer" mb={-4}>{influencerMap[localStorage.getItem('referralCodeInfluencer')]}</Text>
                                        :
                                        null}
                                    <Box
                                        border="0px solid"
                                        boxSizing="border-box"
                                        borderColor="hsl(240, 5.9%, 90%)"
                                        scrollPaddingTop="max(8vh, 40px)"
                                        lineHeight="1.5"
                                        textSizeAdjust="100%"
                                        tabSize={4}
                                        fontFamily='"__Inter_c67b7c","__Inter_Fallback_c67b7c",system-ui,sans-serif'
                                        fontFeatureSettings="normal"
                                        fontVariationSettings="normal"
                                        backgroundColor="rgb(255, 255, 255)"
                                        scrollBehavior="smooth"
                                        webkitFontSmoothing="antialiased"
                                        textRendering="optimizeLegibility"
                                        color="rgb(17, 17, 17)"
                                        display="flex"
                                        flexDirection="column"
                                        p={0}
                                    >
                                        <Heading
                                            className="hero-heading-test-b"
                                            border="0px solid"
                                            boxSizing="border-box"
                                            borderColor="hsl(240, 5.9%, 90%)"
                                            scrollPaddingTop="max(8vh, 40px)"
                                            m={0}
                                            pb={0}
                                            maxWidth="10em"
                                            letterSpacing="-0.032em"
                                            mx="auto"
                                            fontWeight={700}
                                            fontFamily='var(--font-ppmori),"__Inter_c67b7c","__Inter_Fallback_c67b7c",system-ui,sans-serif'
                                            lineHeight="1.1"
                                            fontSize={isMobile ? "2.35rem" : "3.95rem"}
                                            textAlign="center"
                                        >
                                            Your Superhuman AI
                                        </Heading>
                                        <Heading
                                            className="hero-heading-test-b"
                                            border="0px solid"
                                            boxSizing="border-box"
                                            borderColor="hsl(240, 5.9%, 90%)"
                                            scrollPaddingTop="max(8vh, 40px)"
                                            m={0}
                                            mt={5}
                                            pb={0}
                                            maxWidth="10em"
                                            letterSpacing="-0.032em"
                                            mx="auto"
                                            fontWeight={700}
                                            fontFamily='var(--font-ppmori),"__Inter_c67b7c","__Inter_Fallback_c67b7c",system-ui,sans-serif'
                                            lineHeight="1.1"
                                            fontSize={isMobile ? "2.35rem" : "3.95rem"}
                                            textAlign="center"
                                            color={'orange.400'}
                                        >
                                            For Your Documents
                                        </Heading>
                                    </Box>
                                    <Text color={'gray.600'} maxW={'3xl'} fontSize={isMobile ? 'md' : 'lg'} fontWeight={isMobile ? '500' : '600'}>
                                        <Text as="span" fontWeight={isMobile ? '700' : '800'}
                                              fontSize={isMobile ? 'lg' : 'xl'}>5x</Text> {t("your work and read documents faster. For dense PDFs, long case studies, research/academic papers, and much more.")}
                                    </Text>
                                    <Stack spacing={6} direction={'row'}>
                                        <Button
                                            borderRadius={16}
                                            px={isMobile ? 6 : 7}
                                            py={isMobile ? 6 : 7}
                                            fontSize={isMobile ? 18 : 22}
                                            colorScheme={'orange'}
                                            bgGradient="linear(to-r, orange.400, orange.500)"
                                            _hover={{
                                                bgGradient: "linear(to-r, orange.400, orange.600)", // Adjusts the gradient on hover
                                                boxShadow: 'lg' // Adds a slight shadow on hover
                                            }}
                                            onClick={() => {
                                                if (auth.isLoggedIn) {
                                                    fileInput.current.click();
                                                } else {
                                                    onOpenSignUpScreen1();
                                                }
                                            }}>
                                            {auth.isLoggedIn ? t('Upload') : t('Get started')}
                                        </Button>
                                        {/*<Button rounded={'full'} px={6} onClick={() => {*/}
                                        {/*    navigateToFeatures();*/}
                                        {/*}}>*/}
                                        {/*    {t("Learn more")}*/}
                                        {/*</Button>*/}
                                    </Stack>
                                    <Flex w={'100%'} justifyContent={"center"} display={"flex"} flexDirection={'column'}
                                          alignItems={'center'}>
                                        {isUploadLoading ?
                                            <LoadingBar progress={uploadProgress} isMarginBottom={true}/> :
                                            <VStack width={"65%"} height={'11.2rem'} mb={'4rem'} mt='1.1rem' spacing={5}>
                                                <MotionBox
                                                    {...getRootProps()}
                                                    border="2px"
                                                    borderColor="orange.400"
                                                    borderRadius="md"
                                                    borderStyle="dashed"
                                                    textAlign="center"
                                                    p="4"
                                                    cursor="pointer"
                                                    _hover={{ bg: 'gray.100' }}
                                                    animate={isDragActive ? { scale: 1.05 } : { scale: 1 }}
                                                    transition={{ duration: 0.2 }}
                                                    width="100%"
                                                    height="100%"
                                                    onClick={() => {
                                                        if (getDocumentsInStorage().length === 1 && !auth.isLoggedIn) {
                                                            toast({
                                                                title: t("You've reached your document limit in guest mode!"),
                                                                description: t("Please sign up to continue uploading documents."),
                                                                status: "info",
                                                                duration: 9000,
                                                                isClosable: true,
                                                                position: "top",
                                                            });
                                                            onOpenSignUpScreen1();
                                                        }
                                                        else if (!JSON.parse(localStorage.getItem("isProPlan"))
                                                            && localStorage.getItem("isUploadExceeded") === "true") {
                                                            toast({
                                                                title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                                                                status: "info",
                                                                duration: 9000,
                                                                isClosable: true,
                                                                position: "top",
                                                            });
                                                            dispatch(setIsPricingModalOpen(true));
                                                        }
                                                        else {
                                                            fileInput.current.click();
                                                        }
                                                    }}
                                                >
                                                    <input {...getInputProps({ accept: 'application/pdf' })} ref={fileInput}/>
                                                    <Box as="span" color="gray.400" fontSize="4xl">
                                                        ⇧
                                                    </Box>
                                                    <Text fontWeight="bold" color="gray.400">
                                                        {t("Drag & Drop")}
                                                    </Text>
                                                    <Text color="gray.500">{t("or click to browse")}</Text>
                                                    {/*<Box*/}
                                                    {/*    position="absolute"*/}
                                                    {/*    top="12" // Aligns it to the top*/}
                                                    {/*    left="5" // Aligns it to the left*/}
                                                    {/*    bg="transparent" // Adds a background color*/}
                                                    {/*    color="black"*/}
                                                    {/*    p="2"*/}
                                                    {/*    fontSize="sm"*/}
                                                    {/*    fontWeight="bold"*/}
                                                    {/*    transform="rotate(-30deg)" // Rotates the text diagonally*/}
                                                    {/*    transformOrigin="top left" // Ensures the rotation starts from the corner*/}
                                                    {/*>*/}
                                                    {/*    Try it now!*/}
                                                    {/*</Box>*/}
                                                </MotionBox>
                                            </VStack>
                                        }

                                        {auth.isLoggedIn ?
                                            <Box width={!isMobile ? '65%' : '95%'}>
                                                <Text fontSize={'sm'} color={'gray.500'} mt={'-2.5rem'}>or enter a YouTube video:</Text>
                                                <Box width={'100%'} mt={'1.5rem'} mb={'3.1rem'}>
                                                    <URLInput
                                                        urlInput={urlInput}
                                                        setUrlInput={setUrlInput}
                                                        handleSubmit={handleSubmit}
                                                        currentPlaceholder={currentPlaceholder}
                                                        fade={fade}
                                                    />
                                                </Box>
                                            </Box>
                                            : null}




                                        <LogoCarousel/>




                                        <Box width="100vw" justify={'center'} align={'center'}
                                             bg="white" mt={'3rem'}
                                             display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Box width={['100%','89%', '81%', '73%', '64%']}
                                                 borderRadius={!isMobile ? 20 : 0}
                                                 overflow="hidden">

                                                <LazyLoad height={'auto'} width={'100%'} offset={100}>
                                                    <ReactPlayer url={process.env.PUBLIC_URL + '/new_landing_page_video.m4v'}
                                                                 playing={true} // auto-play on load
                                                                 loop={true} // auto-looping
                                                                 playbackRate={1} // 1.5x speed
                                                                 muted={true}
                                                                 controls={true} // show video controls
                                                                 width={'100%'} // full width
                                                                 height="auto">

                                                    </ReactPlayer>
                                                </LazyLoad>
                                            </Box>
                                        </Box>


                                        <Box width="100vw" mt={'2.8rem'} justify={'center'} align={'center'} ref={testimonialsRef}>
                                            <WithSpeechBubbles />
                                        </Box>



                                        <div ref={featuresRef} style={{width: '100vw', display: 'flex',
                                            justifyContent:'center'}}>
                                            <TwoColumnLayout theFileInput={fileInput} onOpenSignUpScreen1={onOpenSignUpScreen1}
                                                             urlInput={urlInput} setUrlInput={setUrlInput} handleSubmit={handleSubmit}
                                                             currentPlaceholder={currentPlaceholder} fade={fade}/>
                                        </div>



                                        <Box width="100vw" mt={'3.5rem'} justify={'center'} align={'center'}>
                                            <BigTestimonial/>
                                        </Box>


                                    </Flex>
                                </Stack>
                            </Container>
                        ) : (
                            renderTabContent()
                        )
                    }
                </VStack>
            </FullScreenTemplateHomePage>
            <Box height={"4rem"}/>
            <LargeWithNewsletter />
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <ScanModal1 isOpenScanModal1={isOpenScanModal1} onCloseScanModal1={onCloseScanModal1} onOpenScanModal1={onOpenScanModal1}/>
            <ScanModalIntro1 isOpenScanModalIntro1={isOpenScanModalIntro1} onCloseScanModalIntro1={onCloseScanModalIntro1}
                             onOpenScanModalIntro1={onOpenScanModalIntro1}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
        </div>
    );
};

export default NewUnifiedHomePage;







