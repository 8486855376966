import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    Image,
    useColorModeValue, HStack, VStack, useBreakpointValue,
} from '@chakra-ui/react'
import {useTranslation} from "react-i18next";
import Marquee from "react-fast-marquee";


export default function WithSpeechBubbles() {
    const {t} = useTranslation();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    const testimonials = [
        {
            text: t("\"As a doctoral student, Honeybear.ai has been an invaluable tool in my research journey. I highly recommend it to fellow researchers & scholars looking to optimize their research process!\""),
            name: 'Jennifer Ku',
            title: 'Student @ Columbia University',
            avatar: '/Big_Testimonial.jpg'
        },
        // {
        //     text: t("\"After using Honeybear.ai quite a bit, I've noticed the responses are better quality than ChatGPT responses. Very impressive.\""),
        //     name: 'Yash Mehta',
        //     title: 'Jurassic Capital',
        //     avatar: '/Yash_Headshot.jpeg'
        // },
        {
            text: t("\"As a STEM student consistently immersed in reading research papers and conducting literature reviews, Honeybear.ai has increased the efficiency of my studying & research!\""),
            name: 'Cecile Schreidah',
            title: 'Student @ Brown University',
            avatar: '/Cecile_Headshot.jpg'
        },
        {
            text: t("\"Honeybear.ai has allowed me to work more efficiently and gain a broader and deeper understanding of topics throughout my college curriculum.\""),
            name: 'Logan Clouser',
            title: 'Student Athlete @ Duke University',
            avatar: '/Logan_Headshot.jpg'
        },
        {
            text: t("\"Honeybear.ai is so much better than other AI websites. The answers it gives are much more accurate & detailed, unlike other sites that often glaze over the document. Truly a lifesaver!\""),
            name: 'Teo Janiga',
            title: 'Student @ Fordham University',
            avatar: '/Teo_Headshot.jpg'
        },

        {
            text: t("\"I've been using Honeybear.ai every day and it's fantastic! I especially like how you can chat with so many PDFs at once.\""),
            name: 'Obi Okasi',
            title: 'Student Researcher, Cornell University',
            avatar: '/Obi_Headshot.JPG'
        },







    ]

    return (
        <Box bg={'white'}>
            {!isMobile ?
                <Container maxW={'100vw'} pt={14} pb={16} as={Stack} spacing={12}>
                    <Box
                        display="flex"
                        overflow="hidden"
                        width="100%"
                        bg={'white'}
                        justify={'center'}
                        align={'center'}
                    >
                        <Marquee speed={50} autoFill={true} gradient={true} gradientWidth={150}>
                            {testimonials.map((testimonial, index) => (
                                <VStack width={'22rem'} height={'18rem'} spacing={7} borderRadius={16} borderColor={'lightgray'}
                                        borderWidth={0.25} mr={6}
                                        py={7} pl={8} pr={7} justify={'space-between'}>
                                    <Text color={'black'} fontSize={17} fontWeight={500} textAlign={'left'}>
                                        {testimonial.text}
                                    </Text>
                                    <HStack spacing={4} width={'100%'}>
                                        <Image
                                            borderRadius={'full'}
                                            boxSize="3rem"
                                            src={testimonial.avatar}
                                            alt="Testimonial Image"
                                            objectFit="cover"
                                        />
                                        <VStack align={'start'} spacing={1}>
                                            <Text color={'black'} fontSize={16} fontWeight={500} textAlign={'left'}>
                                                {testimonial.name}
                                            </Text>
                                            <Text color={'black'} fontSize={14} textAlign={'left'}>
                                                {testimonial.title}
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            ))}
                        </Marquee>
                    </Box>
                </Container> :







                <Container maxW={'100vw'} pt={8} pb={16} as={Stack} spacing={12}>
                    <Box
                        display="flex"
                        // overflow="hidden"
                        width="100%"
                        bg={'white'}
                        justifyContent={'center'}
                        align={'center'}
                    >
                        <VStack>
                            {testimonials.map((testimonial, index) => (
                                <VStack width={'22.3rem'} height={'18rem'} spacing={7} borderRadius={16} borderColor={'lightgray'}
                                        borderWidth={0.25} mb={2}
                                        py={7} px={8} justify={'space-between'}>
                                    <Text color={'black'} fontSize={17.5} fontWeight={500} textAlign={'left'}>
                                        {testimonial.text}
                                    </Text>
                                    <HStack spacing={4} width={'100%'}>
                                        <Image
                                            borderRadius={'full'}
                                            boxSize="3rem"
                                            src={testimonial.avatar}
                                            alt="Testimonial Image"
                                            objectFit="cover"
                                        />
                                        <VStack display={'flex'} alignItems={'flex-start'} spacing={1} textAlign={'left'}>
                                            <Text color={'black'} fontSize={16} fontWeight={500} textAlign={'left'}>
                                                {testimonial.name}
                                            </Text>
                                            <Text color={'black'} fontSize={14} textAlign={'left'}>
                                                {testimonial.title}
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            ))}
                        </VStack>
                    </Box>
                </Container>}
        </Box>
    )
}
